import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/Screenshot_from_2024-01-07_18-11-15-removebg-preview.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import navIcon4 from '../assets/img/leetcode-white.svg'
import navIcon5 from '../assets/img/twitter-white.svg'

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
            <a href="https://www.linkedin.com/in/ali-hassan-325906228/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><img src={navIcon1} alt="" /></a>
            <a href="https://web.facebook.com/people/Ali-Hassan-Mughal/pfbid0j8y4viDft8s1Vzm7EiYjmq4Rrtn9fK932KrPJfhL8JyGRdyZVFPFcYVV7cnEf1RMl/?mibextid=ZbWKwL"><img src={navIcon2} alt="" /></a>
            <a href="https://www.instagram.com/aliha_ssan423/?igsh=M3NjOTYyamFydXdj"><img src={navIcon3} alt="" /></a>
            <a href="https://leetcode.com/alihassan521/"><img src={navIcon4} alt="" /></a>
                <a href="https://twitter.com/hassan444077"><img src={navIcon5} alt="" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
